import React, { useState, useContext, useEffect } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, Button, Text, Progress, Box, VStack, Alert, AlertIcon, Select,
  Checkbox,
  Grid
} from '@chakra-ui/react';
import { FaFileUpload } from 'react-icons/fa';
import { processSheedData } from './process_sheet_data';
import { AuditSheetContext } from '../../../../providers/AuditSheetProvider';
import WorkerBuilder from './workBulider';

function ExcelReader({ updateAuditSheet, providerOptions, currentUpload }) {
  const auditSheetContext = useContext(AuditSheetContext);
  const { industryCodes } = auditSheetContext;

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isImported, setIsImported] = useState(false);
  const [error, setError] = useState(null);
  const [progressMessage, setProgressMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState('');
  const [worker, setWorker] = useState(null);
  const [jsonHeaders, setJsonHeaders] = useState([]);
  const [json, setJSON] = useState([]);
  const [headerMapping, setHeaderMapping] = useState({});
  const [showMappingUI, setShowMappingUI] = useState(false);
  const [showModifierDropdown, setShowModifierDropdown] = useState(false);
  const [checkboxState, setCheckboxState] = useState({
    agreeAllICD: false,
    agreeAllCPT: false,
    CPTCommaSeparated: false,
    ICDCommaSeparated: false,
    modifierDifferentColumn: false,
  });

  const requiredHeaders = [
    'Encounter #', 'Rendering', 'Enc Dt', 'Response', 'Billed ICD', 'Billed CPT', 'Modifier', 'Provider Education'
  ];

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    resetState();
  };

  const resetState = () => {
    setIsLoading(false);
    setIsImported(false);
    setError(null);
    setProgressMessage('');
    setProgress(0);
    setFileName('');
    setJsonHeaders([]);
    setJSON([]);
    setHeaderMapping({});
    setShowMappingUI(false);
  };

  useEffect(() => {
    if (worker) {
      worker.onmessage = async (e) => {
        const { success, json, error } = e.data;

        if (!success) {
          setError(error || 'Error while processing the file.');
          setProgress(0);
          setIsLoading(false);
          worker.terminate();
          return;
        }
        const filteredJson = json.map(row => {
          return Object.entries(row)
              .filter(([key, _]) => !key.startsWith("__EMPTY"))
              .reduce((acc, [key, value]) => {
                  acc[key] = value === "" ? null : value;  // Capture empty cells as null
                  return acc;
              }, {});
        });
        setJSON(filteredJson);
        setJsonHeaders(Object.keys(filteredJson[0])); // Assuming the first object contains headers
        setShowMappingUI(true);
        setIsLoading(false);
      };

      worker.onerror = (e) => {
        setError('Error in processing worker.');
        setProgress(0);
        setIsLoading(false);
        worker.terminate();
      };
    } else {
      const newWorker = new WorkerBuilder();
      setWorker(newWorker);
    }
  }, [worker]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setFileName(file.name);
    setIsLoading(true);
    setProgressMessage('Extracting headers from the file...');
    setProgress(10);

    worker.postMessage({ file });
  };

  const handleHeaderMappingChange = (originalHeader, mappedHeader) => {
    setHeaderMapping((prev) => ({
      ...prev,
      [originalHeader]: mappedHeader,
    }));
  };

  const handleMappingSubmit = () => {

    setProgressMessage('Processing the mapped data...');
    setProgress(50);

    try {
        const processedData = processSheedData(json, headerMapping, checkboxState, providerOptions, currentUpload, industryCodes);
        updateAuditSheet(processedData);
        setProgress(100);
        setProgressMessage('File has been successfully imported!');
        setIsImported(true);
        setShowMappingUI(false);
    } catch (err) {
        setError(err.message || 'Error processing data.');
        setShowMappingUI(false);
        setProgress(0);
    }
  };

  const handleCheckboxChange = (key, value) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  
    // Additional logic for specific checkboxes
    if (key === 'modifierDifferentColumn' && value) {
      // Handle showing modifier dropdown if checked
      setShowModifierDropdown(true);
    } else if (key === 'modifierDifferentColumn' && !value) {
      // Hide modifier dropdown if unchecked
      setShowModifierDropdown(false);
    }
  };
  

  return (
    <>
      <Button
        colorScheme="teal"
        leftIcon={<FaFileUpload />}
        onClick={openModal}
        isDisabled={isLoading}
      >
        {isLoading ? 'Uploading...' : 'Import Sheet'}
      </Button>

      <Modal isOpen={isOpen} onClose={closeModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Excel File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {showMappingUI ? (
              <VStack spacing={6} align="stretch" maxW="900px" mx="auto">
              <Text fontSize="xl" fontWeight="bold" color="gray.800" textAlign="center">
                Map Excel to Rev. Sheet Columns
              </Text>
            
              <Grid templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)" }} gap={6}>
                {requiredHeaders.map((header) => (
                  <Box key={header} borderWidth={1} borderRadius="md" borderColor="gray.300" p={4} bg="white" boxShadow="sm">
                    <Text fontSize="sm" mb={2} fontWeight="medium" color="gray.700">
                      {header}
                    </Text>
                    <Select
                      placeholder="Select Excel Column"
                      onChange={(e) => handleHeaderMappingChange(header, e.target.value)}
                      value={headerMapping[header] || ''}
                      isDisabled={header === 'Modifier' && !showModifierDropdown}
                      variant="filled"
                      color="gray.700"
                      bg="gray.100"
                      borderColor="gray.300"
                      _hover={{ borderColor: 'blue.400' }}
                    >
                      {jsonHeaders.map((jsonHeader) => (
                        <option key={jsonHeader} value={jsonHeader}>
                          {jsonHeader}
                        </option>
                      ))}
                    </Select>
            
                    {/* Add relevant checkboxes */}
                    {header === 'Billed ICD' && (
                      <VStack mt={3} align="start" spacing={2}>
                        <Checkbox
                          onChange={(e) => handleCheckboxChange('agreeAllICD', e.target.checked)}
                          colorScheme="blue"
                          size="sm"
                        >
                          Agree All ICD
                        </Checkbox>
                        <Checkbox
                          onChange={(e) => handleCheckboxChange('ICDCommaSeparated', e.target.checked)}
                          colorScheme="blue"
                          size="sm"
                        >
                          Comma Separated
                        </Checkbox>
                      </VStack>
                    )}
            
                    {header === 'Billed CPT' && (
                      <VStack mt={3} align="start" spacing={2}>
                        <Checkbox
                          onChange={(e) => handleCheckboxChange('agreeAllCPT', e.target.checked)}
                          colorScheme="blue"
                          size="sm"
                        >
                          Agree All CPT
                        </Checkbox>
                        <Checkbox
                          onChange={(e) => handleCheckboxChange('CPTCommaSeparated', e.target.checked)}
                          colorScheme="blue"
                          size="sm"
                        >
                          Comma Separated
                        </Checkbox>
                      </VStack>
                    )}
            
                    {header === 'Modifier' && (
                      <Box mt={3}>
                        <Checkbox
                          onChange={(e) => handleCheckboxChange('modifierDifferentColumn', e.target.checked)}
                          colorScheme="blue"
                          size="sm"
                        >
                          Separate Modifier Column
                        </Checkbox>
                      </Box>
                    )}
                  </Box>
                ))}
              </Grid>
            </VStack>
            ) : isLoading ? (
              <VStack spacing={4} align="center">
                <Text fontSize="lg" fontWeight="medium">{progressMessage}</Text>
                <Progress value={progress} size="xs" colorScheme="teal" width="100%" />
                <Text fontSize="sm" color="gray.500">{fileName || 'No file selected'}</Text>
              </VStack>
            ) : isImported ? (
              <Box textAlign="center">
                <Text fontSize="lg" color="green.500" fontWeight="bold">File successfully imported!</Text>
              </Box>
            ) : error ? (
              <Box textAlign="center">
                <Text color="red.500" fontSize="lg" fontWeight="medium">{error}</Text>
              </Box>
            ) : (
              <VStack spacing={4} align="center">
                <Text fontSize="md">Select an Excel file to import</Text>
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="file-upload"
                />
                <label htmlFor="file-upload">
                  <Button
                    as="span"
                    colorScheme="blue"
                    leftIcon={<FaFileUpload />}
                  >
                    Browse Files
                  </Button>
                </label>
                <Text fontSize="sm" color="gray.500">{fileName || 'No file selected'}</Text>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            {showMappingUI && (
              <Button colorScheme="teal" onClick={handleMappingSubmit}>
                Submit Mapping
              </Button>
            )}
            <Button onClick={closeModal} ml={3}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ExcelReader;
