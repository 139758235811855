import stringSimilarity from 'string-similarity';
import { format } from 'date-fns';
import { updateRVUand$Value } from '../../../../utils/auditsheet.utils';


const FILE_HEADER_MAPPING = {
    "encounter_no": ['Encounter #'],
    "rendering": ['Rendering'],
    "enc_dt": ['Enc Dt'],
    "response": ['Response'],
    "billed_icd": ['Billed ICD'],
    "srvcs_no": ['Billed CPT'],
    "provider_education" : ['Provider Education'],

}

const REQUIRED_FIELDS = {
    "missing_info": null,
    "encounter_no": null,
    "rendering": null,
    "enc_dt": null,
    "billed_icd": null,
    "srvcs_no": null,
    "billed_modifier": null,
    "provider_rvu": null,
    "provider_dollar_value": null,
    "response": null,
    "agree": null,
    "disagree": null,
    "icd_agree": null,
    "icd_disagree": null,
    "audited_icd": null,
    "audited_cpt": null,
    "audited_modifier": null,
    "audited_rvu": null,
    "audited_dollar_value": null,
    "notes": null,
    "provider_education": null,
    "number_complexity": null,
    "amount_complexity": null,
    "risk_complications": null,
    "file_name": null,
    "chart_id": null,
    "row_id": null,
    "id": null
}

export const processSheedData = (
    excelData, 
    headerMapping, 
    checkboxState, 
    providerOptions, 
    currentUpload, 
    industryCodes
) => {
    let result = {
        "index": 1,
        "sheet_name": "Sheet1",
        "data": []
    };
    validateExcelHeaders(excelData);
    result.data = excelData.map(row => {
        let mappedRow = REQUIRED_FIELDS;
        Object.keys(FILE_HEADER_MAPPING).forEach(key => {
            
            mappedRow['chart_id'] = currentUpload.id;
            mappedRow['file_name'] = currentUpload.upload_id;

            if (key === "billed_icd") {
                mappedRow = {...mappedRow, ...mapICD(row, headerMapping, checkboxState.agreeAllICD, checkboxState.ICDCommaSeparated)}; // Map ICD dynamically
            } else if (key === "srvcs_no") {
                mappedRow = {...mappedRow, ...mapCPT(row, headerMapping, checkboxState.agreeAllCPT, checkboxState.CPTCommaSeparated, checkboxState.modifierDifferentColumn)}; // Map CPT dynamically
            } else {
                // For other keys, map as normal
                const data = FILE_HEADER_MAPPING[key]
                    .map(h => row[headerMapping[h]])
                    .find(value => value !== undefined);
                if (data !== undefined) {
                    if(key === "rendering"){
                        mappedRow[key] = getProperPhysicianName(data, providerOptions);
                    } else if(key === "enc_dt"){
                        mappedRow[key] = formatDate(data);
                    } else if(key === "response"){
                        mappedRow[key] = data.slice(0, 200);
                        mappedRow['notes'] = mappedRow[key];
                    }else if(key === "response"){
                        mappedRow[key] = data;
                    }else if(key === "provider_education"){
                        mappedRow[key] = data;
                    }else if(key === "encounter_no"){
                        mappedRow[key] = data;
                    }
                }
            }
        });
        // final_data.data = {...final_data.data, ...result};
        
        return mappedRow;
    });
    result.data = calculate$Value(result, industryCodes);
    return result;   
}

const calculate$Value = (result, industryCodes) => {
    let processdata = result.data;
    processdata.forEach((value, index) => {
        processdata = updateRVUand$Value(processdata, index, industryCodes);
    });
    return processdata;
}

const mapICD = (row, headerMapping, agreeAllICD = false, commaSeperated = false) => {
    let count = 1;
    let data = {};

    // Set agreeAllICD properties if applicable
    if (agreeAllICD) {
        data['icd_agree'] = true;
        data['icd_disagree'] = false;
    }

    const addICDData = (icd) => {
        const key = count === 1 ? 'billed_icd' : `billed_icd_${count}`;
        data[key] = icd;

        if (agreeAllICD) {
            const auditedKey = count === 1 ? 'audited_icd' : `audited_icd_${count}`;
            data[auditedKey] = icd;
        }

        count++;
    };

    // Handle comma-separated ICDs
    if (commaSeperated) {
        const ICDList = row[headerMapping["Billed ICD"]]
            .split(",")
            .map(item => item.replace(/\s+/g, ""));

        ICDList.forEach(addICDData);
    } else {
        // Handle non-comma-separated ICDs
        Object.keys(row).forEach(headerKey => {
            if (getSimilarityRating(headerMapping["Billed ICD"], headerKey) >= 0.6) {
                addICDData(row[headerKey]);
            }
        });
    }

    return data;
};

const mapCPT = (
    row, 
    headerMapping, 
    agreeAllCPT = false, 
    commaSeperated = false,
    modifierDifferentColumn = false, 
) => {
    let count = 1;
    let data = {};

    if (agreeAllCPT) {
        data['agree'] = true;
        data['disagree'] = false;
    }

    const addCPTData = (cpt) => {
        const key = count === 1 ? 'srvcs_no' : `srvcs_no_${count}`;
        data[key] = cpt;

        if (agreeAllCPT) {
            const auditedKey = count === 1 ? 'audited_cpt' : `audited_cpt_${count}`;
            data[auditedKey] = cpt;
        }

        count++;
    };

    const addCPTModifierData = (cpt_split) => {
        const key = count === 1 ? 'srvcs_no' : `srvcs_no_${count}`;
        const modifierKey = count === 1 ? 'billed_modifier' : `billed_modifier_${count}`;
        data[key] = cpt_split.codeUnit;
        data[modifierKey] = cpt_split.mods;

        if (agreeAllCPT) {
            const auditedKey = count === 1 ? 'audited_cpt' : `audited_cpt_${count}`;
            const auditedModifierKey = count === 1 ? 'audited_modifier' : `audited_modifier_${count}`;
            data[auditedKey] = cpt_split.codeUnit;
            data[auditedModifierKey] = cpt_split.mods;

        }

        count++;
    }

    if (commaSeperated) {
        const CPTList = row[headerMapping["Billed ICD"]]
            .split(",")
            .map(item => item.replace(/\s+/g, ""));
        
        if(modifierDifferentColumn){
            CPTList.forEach(addCPTData);
        }else{
            CPTList.forEach(cpt => addCPTModifierData(parseInput(cpt)));
        }
    }else {
        Object.keys(row).forEach(headerKey => {
            if (getSimilarityRating(headerMapping["Billed CPT"], headerKey) >= 0.6) {
                
                if(modifierDifferentColumn){
                    addCPTData(row[headerKey]);
                }else{
                    addCPTModifierData(parseInput(row[headerKey]));
                }
            }
        });    
    }

    if(modifierDifferentColumn){
        data = {...data, ...mapModifier(row, headerMapping, agreeAllCPT)};
    }

    return data
}

const mapModifier = (row, headerMapping, agreeAllCPT = false) => {
    let modifierData = {};
    let count = 1;
    Object.keys(row).forEach(headerKey => {
        if (getSimilarityRating(headerMapping["Modifier"], headerKey) >= 0.6) {
            const modifierKey = count === 1 ? 'billed_modifier' : `billed_modifier_${count}`;
            modifierData[modifierKey] = row[headerKey];

            if (agreeAllCPT) {
                const auditedModifierKey = count === 1 ? 'audited_modifier' : `audited_modifier_${count}`;
                modifierData[auditedModifierKey] = row[headerKey];
    
            }
    
            count++;
        }
    });
    return modifierData;
}

const getSimilarityRating = (match, matchWith) => {
    if(match && matchWith){
        return stringSimilarity.compareTwoStrings(match, matchWith);
    }
    return null;
}

const getProperPhysicianName = (sheetName, providerOptions) => {
    if (sheetName && providerOptions.length > 0) {
        const match = stringSimilarity.findBestMatch(sheetName.toLowerCase(),  providerOptions.map(obj => obj.value.toLowerCase()));
       
        if (match.bestMatch.rating > 0.6) {
            // Get the original object that corresponds to the matched string
            const bestMatchObject = providerOptions.find(obj => obj.value.toLowerCase() === match.bestMatch.target);
            return bestMatchObject.value;
        } else {
            return null;
        }
    }
    return null;
}

const formatDate = (dateStr) => {
    try{
        if(dateStr){
            const parsedDate = new Date(dateStr);
            const formattedDate = format(parsedDate, 'MM-dd-yyyy');
            const enc_dt = /^(?:\d{2}-\d{2}-\d{2}|\d{2}-\d{2}-\d{4})$/
            if (enc_dt.test(formattedDate)) {
                return formattedDate;
            }else if(isNaN(parsedDate.getTime())){
                return null;
            }
        }
        return null;
    }catch{
        return null;
    }
};




const parseInput = (input) => {
    if (!input) return { codeUnit: '', mods: '' }; // Handle empty input gracefully

    const [codeAndMods, unitAndMods] = input.split('*'); // Split by '*'
    let code = '';
    let unit = '';
    let mods = '';

    // If there's only `code` and no '*', handle it
    if (!unitAndMods) {
        [code, ...mods] = codeAndMods.split('-');
        mods = mods.join(',');
        return { codeUnit: code, mods };
    }

    // If both parts exist, extract `code` and `unit`
    [code, ...mods] = codeAndMods.split('-');
    unit = unitAndMods.split('-')[0]; // Unit comes before `-`
    mods = [...mods, ...unitAndMods.split('-').slice(1)].join(',');

    // Construct result
    return {
        codeUnit: unit ? `${code}*${unit}` : code,
        mods
    };
};



function validateExcelHeaders(excelData) {
    if (!Array.isArray(excelData) || excelData.length === 0) {
        throw new Error("Invalid Excel data: no rows found.");
    }

    const firstRow = excelData[0];
    if (typeof firstRow !== 'object') {
        throw new Error("Invalid Excel data: rows should be objects.");
    }
}
